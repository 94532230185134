<template>
<v-app-bar bottom class="shadow-top" color="white"  height="95vh" fixed>
  <div :class="[ previous ?'navBottom' : 'tw-flex tw-justify-end tw-w-full lg:tw-pr-44']">
    <div v-if="previous">
      <base-button  button-text="Back" outlined height="3rem" width="6.938rem"  @click="handleRouteBack" >
        <template #left-icon>
          <arrow-left-icon  style="color: #008EEF" />
        </template>
      </base-button>
    </div>
    <base-button  :button-text="buttonName"  depressed height="3rem" :width="buttonWidth" class="ml-5"  @click="handleRouteTo" :loading="loading" >
      <template #right-icon>
        <arrow-right-icon class="custom-class" />
      </template>
    </base-button>
  </div>
</v-app-bar>
</template>

<script>
import BaseButton from "../../UIComponents/BaseButton";
import { ArrowRightIcon,ArrowLeftIcon } from 'vue-feather-icons'
export default {
  name: "BottomNavbar",
  components: {BaseButton,ArrowRightIcon,ArrowLeftIcon},
  props:{
    routeBack : [String],
    routeTo :[String],
    editor :[Boolean],
    previous : [Boolean],
    loading: [Boolean],
    buttonName: {
      type: String,
      default: 'Next'
    },
    buttonWidth: {
      type: String,
      default: '7.063rem'
    }

  },
  methods:{
    handleRouteBack(){
      this.$emit('click:back')
    },
    handleRouteTo(){
        this.$emit('click:next')
      },

}
}
</script>

<style scoped lang="scss">
.custom-class{
  color: white;
}

.shadow-top{
  //box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.04);
  box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 0px 0px;
  border: 1px solidrgba(0, 0, 0, 0.04) !important;

}

</style>