<template>
<p class="step tw-mt-5">STEP {{count}} OF 9</p>
</template>

<script>
export default {
  name: "StepCount",
  props:{
    count :[Number]
  }

}
</script>

<style scoped lang="scss">
.step{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #9CABB5;
  @media screen and(min-width: 1440px){
    color: #142E70;
  }
}
</style>