<template>
  <div>
<!--    <base-button :outlined="outlined" depressed :button-text="buttonText" @click="dialog = true"></base-button>-->
    <v-dialog
        v-model="dialog"
        max-width="430"
        transition="dialog-top-transition"
        persistent
    >
      <v-card class="tw-p-3 md:tw-p-5 cardBack">
        <div class="tw-flex tw-justify-between">
          <span class="head mb-2">
            {{ title }}
          </span>
            <v-btn icon text @click="$emit('close')">
              <v-icon color="black" class="ml-2">mdi-close</v-icon>
            </v-btn>
        </div>
        <div>
          <span class="subText">
            {{subTitle}}
          </span>
        </div>
        <div class="tw-flex tw-justify-end mb-3 mt-5">
          <base-button @click="$emit('close')" outlined depressed :button-text="noButtonText"></base-button>
          <base-button class="md:tw-ml-2.5 tw-ml-1.5" :loading="loading" @click="callFunction" depressed :button-text="yesButtonText"></base-button>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
export default {
  name: "SplashConfirmModal",
  components: {BaseButton},
  props: {
    buttonText:{
      type:String,
      default:'Cancel'
    },
    dialog:{
      type:Boolean,
      default:false
    },
    loading:{
      type:Boolean,
      default:false
    },
    noButtonText:{
      type:String,
      default:'No',
    } ,
    yesButtonText:{
      type:String,
      default:'Yes',
    } ,
    subTitle:{
      type:String,
      default:'All current progress will be lost!',
    } ,
    title:{
      type:String,
      default:'Cancel Job Posting',
    } ,
    outline:{
      type:Boolean,
      default:true,
    } ,

  },
  methods: {
    async callFunction() {
      this.$emit('function')
    }
  }
}
</script>

<style scoped>
.cardBack {
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
}
.head {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #2B1C1C;
}
.subText {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;

}
</style>
