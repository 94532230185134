<template>
  <div class="tw-flex lg:tw-w-2/5 tw-w-full lg:tw-justify-end" style="height: max-content">
    <div v-if="!roleTips " class="tips-body ">
      <div class="tw-p-5">
        <label class="tipsContent">Skills are the required knowledge for this role</label>
      </div>
    </div>
    <div v-else class="tips-body tw-pb-2">
      <div class=" tw-p-5 tw-overflow-hidden tipsContent" :class="[showMoreBtn? 'tips-max' : 'tips']" v-html="roleTips" />
      <div class="tw-w-full tw-h-8 mt-3 ">
        <label class="tw-p-5 tw-mt-4 show-more" id="isShowMore" @click="handleIsShowMore" >{{showText}}</label>
      </div>
    </div>
  </div>
</template>

<script>
import {getJobSummary} from "../../../services/api/APIService";

export default {
  name: "SuggestionView",
  data(){
    return{
      roleTips: "",
      showMoreBtn : false,
      showText : "Show more",
    }
  },
  methods:{
    handleIsShowMore(){
      this.showMoreBtn = !this.showMoreBtn
      if (this.showText === "Show more"){
        this.showText = "Show less"
      }
      else {
        this.showText = "Show more"
      }
    },
    getSummary(){
      let returnedSelectedDetails = JSON.parse(sessionStorage.getItem("returnedSelectedDetails"))
      const data = {}
      data.site_name = this.$siteName()
      data.level = returnedSelectedDetails.experience_level
      data.role = returnedSelectedDetails.role
      getJobSummary(data).then(res =>{
        this.roleTips = res.data.job_summary
      }).catch(err=>{
            this.$displaySnackbar({message:err.response.data.detail, success:false})
          })
    }
  },
  created() {
    this.getSummary()
  }
}
</script>

<style scoped lang="scss">
.tips-body{
  background: #F7FCFF;
  border-radius: 16px;
@media screen and(min-width: 1224px) {
  border: 1px solid #C5E7FF;
  width: max-content;
}
}
.tips{
  max-height: 10rem;
  max-width: 20rem;
  @media screen  and (max-width: 1024px){
    max-width: 100%;
  }
}
.tips-max{
  min-height: 10rem;
  max-width: 20rem;
  height: 30rem;
  overflow: auto;
  @media screen  and (max-width: 1024px){
    max-width: 100%;
  }
}
.tips-max::-webkit-scrollbar {
  width: 3px !important;
  height: 2px !important;
}
.tips-max::-webkit-scrollbar-track {
  box-shadow: white;
  border-radius: 5px;
  height: 2px !important;
}
.tips-max::-webkit-scrollbar-thumb {
  background: #C4C4C4;
  border-radius: 5px;

}
.show-more{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #9CABB5;
  cursor: pointer;
@media screen  and (min-width: 1224px){
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-decoration-line: underline;
  color: #008EEF;
}
}
.tipsContent{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #333333;
}

</style>