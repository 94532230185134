<template>
  <div>
    <Header @cancel="openModal" />
    <div>
      <div class="tw-pt-20 tw-pb-20 tw-w-full applyFlexCenter">
      <slot name="topContent">

      </slot>
      </div>
    </div>
<!--    <div class="applyFlexCenter scroll" style="margin: 0 auto">-->
<!--    <div class=" tw-pl-5 tw-pr-5 tw-w-full lg:tw-w-4/5 lg:tw-pt-40 tw-flex-col tw-relative ">-->
<!--      <div class="stepContent " style="">-->
<!--        <slot name="topContent">-->

<!--        </slot>-->

<!--      </div>-->
<!--    </div>-->
<!--    </div>-->
      <slot name="bottomNav">

      </slot>
    <SplashConfirmModal @close="closeModal" @function="gotoDashboard" :dialog="dialog" :subTitle="subText"/>

  </div>
</template>

<script>
import Header from "../../onboarding/reuseables/Header";
import SplashConfirmModal from "@/components/UIComponents/SplashConfirmModal";
export default {
  name: "JobPostingLayout",
  components: {SplashConfirmModal, Header},
  data(){
    return{
      dialog:false,
      subText : "All current progress will be lost! "
    }
  },
  methods:{
    gotoDashboard() {
      this.dialog= false
      sessionStorage.removeItem('returnedSelectedDetails')
      return this.$router.push({name: "RecruiterDashboard"})
    },
    openModal(){
      if(this.$route.name === 'Job-stepOne' || this.$route.name === 'Job-stepTwo' || this.$route.name === 'Job-stepThree') {
        this.dialog = true
      } else {
        this.$router.push({name: 'JobSummaryOverview'})
        sessionStorage.removeItem("returnedSelectedDetails")
      }
    },
    closeModal(){
      this.dialog = false
    }

  }
}
</script>

<style scoped lang="scss">
.layout-min-width{
  min-width: 63rem;
}
</style>
