<template>
<job-posting-layout>
  <template #topContent>
    <Loader v-if="pageLoading" :loading="pageLoading" />
    <div class="stepContent" v-if="!pageLoading">
        <step-count :count="4" />
        <step-title title="What are the skills required for this role?" />
        <small style="color: red" v-if="showError.state">{{showError.message}}</small>
        <div class=" tw-flex tw-w-full  tw-flex-col-reverse lg:tw-flex-row lg:tw-pt-5">
          <div class="lg:tw-w-3/4">
            <p class="skills tw-mt-10 lg:tw-mt-0">SKILLS</p>
            <div class="tw-flex tw-flex-row tw-flex-wrap tw-w-full lg:tw-w-9/12">
              <div v-for="skill in returnSkills" :key="skill" @click="handleSelectedSkill(skill)"
                   :class="[selectedSkills.includes(skill) ? 'selected' : 'select']" class="tw-mr-6 tw-pt-2.5 text-wrap tw-mt-5 text-capitalize">{{skill}}</div>
            </div>
            <div class=" lg:tw-w-9/12 tw-mt-10" v-show="showOtherSkills===1">

              <v-combobox
                  v-model="otherSkills"
                  color="linear-gradient(0deg, #FFFFFF, #FFFFFF)"
                  label="Enter skills"
                  solo
                  height="auto"
                  multiple
                  required
                  chips
                  id="otherSkills"
              >
                <template v-slot:selection="data">
                  <v-chip
                      v-bind="data.attrs"
                      :input-value="data.otherSkills"
                      color="#0C8B99"
                      @click="data.select"
                      id="removeEmail"
                  >
                    {{ data.item }}
                    <div @click="handleRemoveSkill(data.item)">
                      <XIcon class="tw-w-4 tw-ml-2"/>
                    </div>
                  </v-chip>
                </template>
              </v-combobox>
              <span class="smallTips tw-flex tw-items-center"><AlertCircleIcon class="tw-w-3 tw-mr-2"/>Please press ENTER after entering skill for this role</span>
            </div>

          </div>
          <suggestion-view/>
        </div>
    </div>
  </template>
  <template #bottomNav>
    <bottom-navbar  @click:back="routeBack" :button-name="$route.query.edit?'Save ':'Next '"
                    :previous="!$route.query.edit" @click:next="handleUpdatePosting" :loading="buttonLoading" />
  </template>
</job-posting-layout>
</template>

<script>
import JobPostingLayout from "../layouts/JobPostingLayout";
import BottomNavbar from "../reuseables/BottomNavbar";
import StepCount from "../reuseables/StepCount";
import StepTitle from "../reuseables/StepTitle";
import Loader from "../../UIComponents/reusablesIcon/Loader";
import {AlertCircleIcon, XIcon} from "vue-feather-icons";
import {getJobById, getSkillsAndFramework, updateJobById} from "../../../services/api/APIService"
import SuggestionView from "./SuggestionView";

export default {
  name: "StepFour",
  components: {SuggestionView, StepTitle, StepCount, BottomNavbar, JobPostingLayout,XIcon,AlertCircleIcon, Loader},
  data(){
    return{
      pageLoading : true,
      returnSkills: [],
      returnFrameworks:[],
      otherSkills:[],
      otherFrameworks:[],
      selectedSkills:[],
      selectedFrameworks:[],
      showOtherSkills : 0,
      showOtherFrameworks : 0,
      showError : {
        message : "",
        state : false
      },
    buttonLoading : false
    }
  },
  methods:{
    routeBack(){
        this.$router.push({name: 'Job-stepThree'})
    },
    handleSelectedSkill(skill) {
      if (this.selectedSkills.includes(skill)) {
        this.selectedSkills = this.selectedSkills.filter(s => s !== skill)
      } else this.selectedSkills.push(skill)
      if (skill.toLowerCase() === 'others'){
        if (this.showOtherSkills) this.showOtherSkills = 0
        else this.showOtherSkills = 1
      }
    },
    handleRemoveSkill(skill){
      this.otherSkills= this.otherSkills.filter(item => item !== skill)
    },
   async getSkillAndFramework(role){
        this.pageLoading = true
      if(role){
        try {
          const {data: {Skills}} = await getSkillsAndFramework({'site_name':this.$siteName(),'role':role.role})

          if (!Skills.length){
            this.showOtherSkills = 1
          }
          else {
            this.returnSkills = Skills
          }

        } catch (e) {
          console.log(e)
        } finally {
          this.pageLoading = false
        }
      }

    },

   async handleUpdatePosting(){
      this.buttonLoading = true
      let job = JSON.parse(sessionStorage.getItem('returnedSelectedDetails'))
      let jobId = job.id
      let currentJob = {};
        if ((!this.selectedSkills && !this.otherSkills)) {
          this.showError.message = "At least one skill should be selected or entered."
          this.showError.state = true
        }
        else {
          this.showError.message = ""
          this.showError.state = false
        }
        currentJob.required_skills = this.selectedSkills.concat(this.otherSkills)
        currentJob.site_name = this.$siteName()
        if (jobId){
        await updateJobById(jobId,currentJob).then(() =>{
          this.buttonLoading = false
          if (this.$route.query.edit){
            this.$router.push({name:'JobSummary'})
          }
          else {
            this.$router.push({name : 'Job-stepFive'})
          }
            })
              .catch(err =>{
                this.buttonLoading = false
                console.log(err.response.data.detail)
                this.$displaySnackbar({message:err.response.data.detail, success:false})
              })
        }},
   async populateSkillsAndFrameworks(jobId,role){
       await this.getSkillAndFramework(role)
      let data = {};
        data.id = jobId
        data.site_name = this.$siteName()
     await getJobById(data).
     then(({data: {required_skills}}) =>{
        if (required_skills.length) {
          this.selectedSkills = required_skills.filter(skill => this.returnSkills.includes(skill))
          if (required_skills.some(skill => !this.returnSkills.includes(skill))) {
            this.showOtherSkills = 1
            this.otherSkills = required_skills.filter(skill => !this.returnSkills.includes(skill))
          }
        }
        required_skills.map((selectedSkill)=>{
          this.returnSkills.map((skill)=>{
            if (selectedSkill === skill.name){
              skill.isSelected = true
            }
          })
        })

      }).catch(err =>{
            console.log(err.response.data.detail)
            this.$displaySnackbar({message:err.response.data.detail, success:false})
          })
    },
    updatePage(){
      let job = JSON.parse(sessionStorage.getItem('returnedSelectedDetails'))
      const data = {}
      data.role = job.role
      data.site_name = this.$siteName()
      let jobId = job.id
      if (jobId && data.role){
        this.populateSkillsAndFrameworks(jobId,data)
      }else{
        this.getSkillAndFramework(data)
      }
    }
  },
  created() {
    this.updatePage()
  }
}
</script>

<style scoped lang="scss">

.chip{
  background: #0C8B99;
}

::v-deep .v-input--is-focused {
  border: none !important;
}
::v-deep .v-text-field__details {
  display: none !important;
}
::v-deep .v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
  min-height: 48px !important;
  height: auto !important;
}

::v-deep .v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border: 1px solid #9CABB5;
  box-sizing: border-box;
  border-radius: 6px;
}
::v-deep .mdi-menu-down::before {
  display: none;
}
::v-deep .v-chip .v-chip__content {
  color: white;
}
.smallTips{
  font-size: 12px;
  line-height: 16px;
  color: #1E323F;
}
</style>












