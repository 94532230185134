<template>
<p class="step-title tw-mt-4">{{title}}</p>
</template>

<script>
export default {
  name: "StepTitle",
  props:{
    title:[String]
  }
}
</script>

<style scoped>

</style>